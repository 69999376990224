/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContentType } from './contentType';
import { Updated } from './updated';
import { CmisObjectType } from './cmisObjectType';


export interface EntryType { 
    id?: string;
    title: string;
    summary?: string;
    object?: CmisObjectType;
    updated?: Updated;
    content?: ContentType;
}

